import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import imagebg from "../img/404-bg.svg";
import imagecomet from "../img/404-comet.svg";
import imageman from "../img/404-man.svg";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const NotFoundPage = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={`true`} backgroundColour={`primary`}>
      <Seo
        title={
          "Whoops" +
          meta.sitePageTitleSep +
          "404 Error" +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + "/404/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Whoops" + meta.sitePageTitleSep + "404 Error"}
        facebookTitle={"Whoops" + meta.sitePageTitleSep + "404 Error"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <header id="hero" className="hero hero_404 shadow">
        <div className="position-relative container-fluid my-md-5">
          <div className="pb-md-5 row contained-xl justify-content-center">
            <div className="text-center order-1 order-md-0 text-md-left py-2 py-md-0 pb-md-5 col-lg-4 col-md-5 offset-md-1 offset-lg-0 my-md-2 pt-lg-5 text-left">
              <h1 className="display-2">Feeling Lost?</h1>
              <h2 className="text-secondary mb-3">404 error? Old Link?</h2>
              <h3 className="font-weight-normal mb-3">
                Don’t worry, we’re here for you…
                <br /> We can lend a hand?
              </h3>
              <Link to="/" className="btn btn-secondary btn-lg">
                Let's take you home
              </Link>
            </div>
            <div className="mt-5 mt-md-0 col-7 col-md-6 my-md-2 align-self-center text-left">
              <div className="position-relative">
                <img src={imagebg} className="img-fluid" alt="background" />
                <img
                  src={imagecomet}
                  className="hero_404__comet img-fluid position-absolute"
                  alt="comet"
                />
                <img
                  src={imageman}
                  className="hero_404__image img-fluid position-absolute"
                  alt="spaceman"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default NotFoundPage;
